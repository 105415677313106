import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import Footer from "../components/Footer";
import Header from "../components/Header";
import CookiePreferences from "../components/common/CookiePreferences";
// Internal Components
import { importRetry } from "../components/Utils";
const TrackingScripts = dynamic(() =>
  importRetry(() => import("../components/TrackingScripts"))
);
import FullPageSpinner from "../components/common/FullPageSpinner";

// Template files
import Petition from "./Petition";
import Fundraiser from "./Fundraiser";

import {
  useFeatureIsOn,
  useFeatureValue,
  useGrowthBook,
} from "@growthbook/growthbook-react";

function TemplateWrapper({ templateName, templateProps, forceScrollToDonate }) {
  const router = useRouter();
  const { isReady, push, query } = router;
  const { ready } = useGrowthBook();
  const { campaign_slug } = templateProps;

  const [loading, setLoading] = useState(true);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const goFundMeOn = useFeatureIsOn("go-fund-me");
  const amnestyOn = useFeatureIsOn("amnesty-layout");
  const campaignRedirect = useFeatureValue("campaign-redirect", {
    redirect: false,
    loading: false,
    timer: 10,
  });

  useEffect(() => {
    const lowerTemplateName = templateName.toLowerCase();
    const isFundraiser = lowerTemplateName.includes("fundraiser");
    const isPetition = lowerTemplateName.includes("greenpeace");

    if (ready && isReady) {
      if (
        (isFundraiser && (goFundMeOn || amnestyOn)) ||
        (isPetition && campaignRedirect.redirect)
      ) {
        // If the feature is on, prepare for redirection
        setShouldRedirect(true);
        // Delay setting loading to false to provide a buffer for redirection and avoid flickering
        setTimeout(() => {
          setLoading(false);
        }, 350);
      } else {
        setLoading(false);
      }
    }
  }, [
    ready,
    isReady,
    templateName,
    goFundMeOn,
    amnestyOn,
    shouldRedirect,
    campaign_slug,
  ]);

  // Redirect logic
  if (shouldRedirect) {
    // Perform redirection, e.g., push("fund/slug");

    let segment = "a"; // Default value;
    if (goFundMeOn) segment = "fund";
    else if (amnestyOn) segment = "gift";
    else if (campaignRedirect.redirect) segment = "campaign";

    const pathname = `/${segment}/${campaign_slug}`;

    if (query.campaign_slug) delete query.campaign_slug;
    console.log("Redirecting to", pathname, JSON.stringify(query));

    push({ pathname, query });

    return <FullPageSpinner />; // Prevent rendering during redirection
  }

  // Loading state
  if (loading || !ready || shouldRedirect) {
    return <FullPageSpinner />;
  }

  return (
    <>
      <Header />
      {templateName.toLowerCase().includes("petition") && (
        <Petition {...templateProps} scrollToDonate={forceScrollToDonate} />
      )}
      {templateName.toLowerCase().includes("fundraiser") && (
        <Fundraiser {...templateProps} />
      )}
      <Footer />
      <CookiePreferences />
      <TrackingScripts />
    </>
  );
}

export default TemplateWrapper;
